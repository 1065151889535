import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {ADMIN_MODULE, LOGIN_PATH} from './application/types'
import {LoginComponent} from './components/login/login.component'

const routes: Routes = [
  {
    path: ADMIN_MODULE,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'admin',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

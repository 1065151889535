import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MAT_DATE_LOCALE} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {SpbPaginatorSv, ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AuthInterceptor} from './application/auth.interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {ResponseInterceptor} from './application/response.interceptor'
import {ComponentsModule} from './components/components.module'
import {ConfigService} from './services/config.service'
import {DecoratorService} from './services/decorators/decorator.service'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ComponentsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    BankIdModule
  ],
  providers: [
    /**
     * Check if you really need this in your application,
     * it used to be everywhere but not anymore.
     */
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [DecoratorService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    // Provide the interceptors
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline',
        subscriptSizing: 'dynamic'
      }
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'sv'
    },
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
